var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.genres, function(genre, index) {
      return _c("li", { key: index }, [
        _c(
          "a",
          {
            attrs: { href: "javascript:;" },
            on: {
              click: function($event) {
                return _vm.$emit("setGenre", genre.label)
              }
            }
          },
          [_vm._v(" " + _vm._s(genre.label) + " ")]
        ),
        _vm._v(" (" + _vm._s(genre.count) + ") ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }