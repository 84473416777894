var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "tv-show-card__item" }, [
    _c("a", { attrs: { href: _vm.show.imdb, target: "_blank" } }, [
      _c("img", {
        staticClass: "tv-show-card__item-poster",
        attrs: { src: _vm.show.poster, alt: _vm.show.title }
      })
    ]),
    _c("h3", { staticClass: "tv-show-card__item-heading" }, [
      _vm._v(_vm._s(_vm.show.title) + " "),
      _c("small", [_vm._v("(" + _vm._s(_vm.show.year) + ")")])
    ]),
    _c("p", { staticClass: "tv-show-card__item-genre" }, [
      _vm._v(_vm._s(_vm.$options.filters.inlineList(_vm.show.genre)))
    ]),
    _c("p", { staticClass: "tv-show-card__item-rating" }, [
      _vm._v("iMDb rating: " + _vm._s(_vm.show.rating) + " ")
    ]),
    _c("p", { staticClass: "tv-show-card__item-description" }, [
      _vm._v(" " + _vm._s(_vm.show.description) + " ")
    ]),
    _c("p", { staticClass: "tv-show-card__item-link" }, [
      _c("a", { attrs: { href: _vm.show.imdb } }, [_vm._v("View in IMDb")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }