var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pagination" },
    [
      _c("li", { staticClass: "pagination__item pagination__item--prev" }, [
        _c(
          "button",
          {
            staticClass: "pagination__button",
            attrs: { disabled: _vm.currentPage == 1 },
            on: {
              click: function($event) {
                return _vm.$emit("prevPage")
              }
            }
          },
          [_vm._v(" ← ")]
        )
      ]),
      _vm._l(_vm.totalPages, function(pageNumber, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "pagination__item",
            class: { "is-active": _vm.currentPage == pageNumber },
            attrs: {
              "aria-label": ["Goto page " + pageNumber],
              "aria-current": _vm.currentPage == pageNumber ? true : false
            }
          },
          [
            _c(
              "button",
              {
                staticClass: "pagination__button",
                on: {
                  click: function($event) {
                    return _vm.$emit("setPage", pageNumber)
                  }
                }
              },
              [_vm._v(" " + _vm._s(pageNumber) + " ")]
            )
          ]
        )
      }),
      _c("li", { staticClass: "pagination__item pagination__item--next" }, [
        _c(
          "button",
          {
            staticClass: "pagination__button",
            attrs: { disabled: _vm.currentPage >= _vm.totalPages.length },
            on: {
              click: function($event) {
                return _vm.$emit("nextPage")
              }
            }
          },
          [_vm._v(" → ")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }