var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tv-shows" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Tv shows")]),
    _c(
      "nav",
      {
        staticClass: "tv-shows-nav",
        attrs: { role: "navigation", "aria-label": "Navigation" }
      },
      [
        _c("Pagination", {
          attrs: { totalPages: _vm.pages, currentPage: _vm.page },
          on: {
            setPage: function($event) {
              _vm.page = $event
            },
            prevPage: function($event) {
              _vm.page--
            },
            nextPage: function($event) {
              _vm.page++
            }
          }
        }),
        _c("p", { staticClass: "paragraph tv-shows__display" }, [
          _vm._v(" Display as: "),
          _c(
            "a",
            {
              staticClass: "tv-shows__sorting-link",
              class: { "is-active": _vm.tvShowDisplay == "TvShowItemCard" },
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setTvShowDisplay("TvShowItemCard")
                }
              }
            },
            [_vm._v(" cards ")]
          ),
          _vm._v("or "),
          _c(
            "a",
            {
              staticClass: "tv-shows__sorting-link",
              class: { "is-active": _vm.tvShowDisplay == "TvShowItemRow" },
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setTvShowDisplay("TvShowItemRow")
                }
              }
            },
            [_vm._v(" rows ")]
          )
        ]),
        _c("p", { staticClass: "paragraph tv-shows__sorting" }, [
          _vm._v(" and order by: "),
          _c(
            "a",
            {
              staticClass: "tv-shows__sorting-link",
              class: [
                _vm.orderTvShowsByDesc ? "sorted-desc" : "sorted-asc",
                { "is-active": _vm.orderTvShowsBy == "title" }
              ],
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setOrderBy("title")
                }
              }
            },
            [_vm._v(" title")]
          ),
          _vm._v(", "),
          _c(
            "a",
            {
              staticClass: "tv-shows__sorting-link",
              class: [
                _vm.orderTvShowsByDesc ? "sorted-desc" : "sorted-asc",
                { "is-active": _vm.orderTvShowsBy == "rating" }
              ],
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setOrderBy("rating")
                }
              }
            },
            [_vm._v(" rating")]
          ),
          _vm._v(" or "),
          _c(
            "a",
            {
              staticClass: "tv-shows__sorting-link",
              class: [
                _vm.orderTvShowsByDesc ? "sorted-desc" : "sorted-asc",
                { "is-active": _vm.orderTvShowsBy == "year" }
              ],
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setOrderBy("year")
                }
              }
            },
            [_vm._v(" year")]
          )
        ])
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "tv-shows__overview", class: _vm.tvShowsOverviewType },
      _vm._l(_vm.displayedPosts, function(show) {
        return _c(_vm.tvShowDisplay, {
          key: show[".key"],
          tag: "component",
          attrs: { show: show }
        })
      }),
      1
    ),
    _c(
      "nav",
      {
        staticClass: "tv-shows-nav",
        attrs: { role: "navigation", "aria-label": "Navigation" }
      },
      [
        _c("Pagination", {
          attrs: { totalPages: _vm.pages, currentPage: _vm.page },
          on: {
            setPage: function($event) {
              _vm.page = $event
            },
            prevPage: function($event) {
              _vm.page--
            },
            nextPage: function($event) {
              _vm.page++
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }